<template>
    <div class="IndustryChain">
        <img src="../assets/cyl.png" width="100%" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>